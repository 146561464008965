import AccountService from '@/services/account.service.js'
import EventBus from '@/util/EventBus'
import UserService from '@/services/user.service'

export default {
  name: 'BillingForm',
  props: {
    billing: {
      type: Object,
      require: true,
    },
  },
  data () {
    return {
      user: this.$store.getters['auth/getUser'],
      loading: false,
      taxSystems: [
        { text: 'Particular', value: 'INDIVIDUAL' },
        { text: 'Empresa', value: 'COMPANY' },
      ],
    }
  },
  methods: {
    setLabelRazonSocial () {
      return this.billing.setRazonSocial(this.billing.profile.tax_system)
    },
    setLabelCIF (isoCode = 'ES', isViesChecked = '0') {
      isoCode = this.user.isoCountry.toUpperCase() || this.user.isoTld.toUpperCase() || isoCode
      if (this.billing.validateIndividualTaxSystem(this.billing.profile.tax_system)) {
        return this.billing.setIndividualCountryMcc(isoCode)
      }

      if (this.billing.validateCompanyTaxSystem(this.billing.profile.tax_system)) {
        return this.billing.setCompanyCountryMcc(isoCode, isViesChecked)
      }
    },
    updateProfileFiscal () {
      this.loading = true
      this.billing.profile.perfil = this.billing.user.username
      AccountService.updateBilling(this.billing.profile, this.billing.user.id)
        .then(
          () => {
            this.$emit('updated-profile')
            this.setShowBillingProfileByUser()
          },
          (error) => {
            if (error.response.status === 422) {
              let errorsAlert = ''
              const errors = error.response.data
              for (const index in errors) {
                errorsAlert += this.$errorMessages.getValidationMessage(
                  errors[index].error,
                  index, errors[index].values,
                ) + '<br>'
              }
              return EventBus.$emit('showAlert', 'danger', errorsAlert)
            }
            if (error.response.status === 400) {
              if (error.response.data.message) {
                return EventBus.$emit(
                  'showAlert',
                  'danger',
                  error.response.data.message,
                )
              } else {
                return EventBus.$emit(
                  'showAlert',
                  'danger',
                  this.$errorMessages.getMessage(error.response.data.code),
                )
              }
            }

            EventBus.$emit(
              'showAlert',
              'danger',
              this.$t('Error al intentar actualizar perfil del usuario'),
            )
            // AccountService.errorResponse(
            //   error.response.status,
            //   this.$t('Error al intentar actualizar perfil del usuario'),
            //   this.$t(error.response.data.message),
            // )
          },
        )
        .finally(() => {
          this.loading = false
        })
    },
    setShowBillingProfileByUser () {
      UserService.setShowBillingProfileByUser({ showBillingProfile: false }).then(() => {
        this.changeUserShowBillingProfile(false)
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar las preferencias').toString())
      })
    },
    changeUserShowBillingProfile (showBillingProfile) {
      const user = JSON.parse(localStorage.getItem('user'))
      user.showBillingProfile = showBillingProfile
      localStorage.setItem('user', JSON.stringify(user))
    },
  },
}
