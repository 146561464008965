import { Billing } from '@/models/account/Billing.js'
import AccountService from '@/services/account.service.js'
import ProfileMenu from '../Common/ProfileMenu/ProfileMenu.vue'
import SupportCard from '../Common/SupportCard/SupportCard.vue'
import BillingInfo from './BillingInfo/BillingInfo.vue'
import BillingForm from './BillingForm/BillingForm.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'BillingProfile',
  components: {
    HeaderTopDashboard,
    ProfileMenu,
    SupportCard,
    BillingInfo,
    BillingForm,
  },
  data () {
    return {
      billing: new Billing(),
    }
  },
  computed: {
    hasBillingProfiles () {
      return this.billing?.billingProfiles?.length > 0
    },
  },
  methods: {
    setBillingStep (billingProfiles) {
      if (billingProfiles.length > 0) {
        this.billing.step = 1
      } else {
        this.billing.step = 2
      }
    },
    getBilling () {
      AccountService.getBilling()
        .then(
          (response) => {
            this.billing = new Billing(
              response.billingProfiles,
              response.countryMcc,
              response.from,
              response.params,
              response.user,
            )
            // this.billing.billingProfiles = []
            this.setBillingStep(this.billing.billingProfiles)
            this.billing.show = true
          },
          (error) => {
            AccountService.errorResponse(
              error.response.status,
              this.$t('Error al intentar ver perfil fiscal'),
              this.$t(error.response.data.message),
            )
          },
        )
        .finally(() => {
          this.loading = false
        })
    },
  },
  created () {
    this.getBilling()
  },
}
