import $t from '../../util/Translator'

const TAX_SYSTEM_INDIVIDUAL = 'INDIVIDUAL'
const TAX_SYSTEM_COMPANY = 'COMPANY'
const PROFILE_IS_VALID_ACTIVE = 1
const PROFILE_IS_VALID_REJECT = 2

export class Billing {
  billingProfiles
  countryMcc
  from
  param
  user
  show = false
  step = 1
  profile = {
    tax_system: 'INDIVIDUAL',
    perfil: '',
  }

  constructor (
    billingProfiles = [],
    countryMcc = {},
    from = null,
    param = null,
    user = {},
  ) {
    this.billingProfiles = billingProfiles
    this.countryMcc = countryMcc
    this.from = from
    this.param = param
    this.user = user
  }

  validateIndividualTaxSystem (taxSystem) {
    if (taxSystem === TAX_SYSTEM_INDIVIDUAL) {
      return true
    }

    return false
  }

  validateCompanyTaxSystem (taxSystem) {
    if (taxSystem === TAX_SYSTEM_COMPANY) {
      return true
    }

    return false
  }

  setRazonSocial (taxSystem) {
    if (taxSystem === TAX_SYSTEM_INDIVIDUAL) {
      return $t('Nombre completo')
    }

    if (taxSystem === TAX_SYSTEM_COMPANY) {
      return $t('Razón social')
    }
  }

  setIndividualCountryMcc (isoCode) {
    if (isoCode === 'ES') {
      return $t('N.I.F')
    } else if (isoCode === 'IN') {
      return $t('GST ID')
    } else {
      return $t('Número de identificación')
    }
  }

  setCompanyCountryMcc (isoCode, isViesChecked) {
    if (isoCode === 'ES' && isViesChecked !== '0') {
      return $t('Número de IVA')
    } else if (isoCode === 'ES') {
      return $t('C.I.F')
    } else if (isoCode === 'IN') {
      return $t('GST ID')
    } else {
      return $t('Número de IVA / Número de registro')
    }
  }

  setProfileStatus (status) {
    if (status === PROFILE_IS_VALID_ACTIVE) {
      return $t('Perfil activo')
    } else if (status === PROFILE_IS_VALID_REJECT) {
      return $t('Perfil activo')
    } else {
      return $t('Perfil Pendiente de validación')
    }
  }
}
